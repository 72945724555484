import Link from 'next/link'

import ImageWithAspectRatio from 'components/ImageWithAspectRatio'

import { styled } from 'styles/stitches.config'

import { formatMoney } from 'utils/money'

import type { StoreItem } from 'types/api/store'

const Item = styled('div', {
  alignItems: 'flex-start',
  borderTop: '1px solid $grey200',
  color: '$grey1000',
  cursor: 'pointer',
  display: 'flex',
  padding: '$m $s',
  gap: '$xs'
})

const ItemInfo = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxs'
})

const ItemName = styled('div', {
  fontSize: '$body1',
  fontWeight: '$bold',
  lineHeight: '$body1',
})

const ItemPrice = styled('div', {
  fontSize: '$body2',
  fontWeight: '$bold',
  lineHeight: '$body2',
})

const ItemDescription = styled('div', {
  color: '$grey800',
  fontSize: '$body3',
  lineHeight: '$body3',
})

const ItemImage = styled('div', {
  borderRadius: '$xs',
  flex: '0 0 auto',
  overflow: 'hidden',
  width: 92, height: 92,
})

const Tag = styled('span', {
  padding: '$xxxs $xs',
  fontSize: '$tagLabel',
  borderRadius: '$xxxs',
  width: 'fit-content'
})

const NewTag = styled(Tag, {
  color: '$blue600',
  background: '$blue100'
})

const PopularTag = styled(Tag, {
  color: '$green800',
  background: '$green100'
})

type Props = {
  item: Pick<StoreItem, 'description' | 'itemId' | 'name' | 'photo' | 'status'> & { price: number }
  storeId: string
}

const StoreMenuItem = ({ item, storeId }: Props) => {

  return (
    <Link
      href={{
        pathname: '/explore/stores/[storeId]/[itemId]',
        query: {
          storeId,
          itemId: item.itemId,
        },
      }}
      key={item.itemId}
      passHref
    >
      <Item>
        <ItemInfo>
          <ItemName>{item.name}</ItemName>
          {item.status === 'new' && (
            <NewTag>New Item!</NewTag>
          )}
          {item.status === 'popular' && (
            <PopularTag>Most Popular</PopularTag>
          )}
          {item.description && (
            <ItemDescription>{item.description}</ItemDescription>
          )}
          <ItemPrice>{formatMoney(item.price)}</ItemPrice>
        </ItemInfo>

        {item.photo && (
          <ItemImage>
            <ImageWithAspectRatio aspectRatio={100} alt={item.name} src={item.photo} />
          </ItemImage>
        )}
      </Item>
    </Link>
  )
}

export default StoreMenuItem
